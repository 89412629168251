<template>
  <div>
    <el-form class='radius_bg' :model='searchCondition' inline size='mini' @submit.native.prevent>
      <el-form-item label='商品名'>
        <el-input v-model='searchCondition.product_title' clearable placeholder='商品名' @change='handleQuery' />
      </el-form-item>
      <el-form-item label='快递'>
        <el-input v-model='searchCondition.com_label' style='width: 130px' clearable placeholder='快递'
                  @change='handleQuery' />
      </el-form-item>
      <el-form-item label='快递单号'>
        <el-input v-model='searchCondition.nu' style='width: 160px' clearable placeholder='快递单号'
                  @change='handleQuery' />
      </el-form-item>
      <el-form-item label='商家'>
        <el-input v-model='searchCondition.customer_name' clearable placeholder='商家' @change='handleQuery' />
      </el-form-item>
      <el-form-item v-if="userPermissions.indexOf('product_specimen_all')>-1" label='负责人'>
        <ProductLeaderSelect ref='refProductLeader' :leader-id.sync='searchCondition.leader_id'
                             leader-type='product_specimens'
                             style='width: 150px'
                             @selected='selectedLeader' />
      </el-form-item>
      <el-form-item v-else label='负责人'>
        {{ userInfo.nickname }}
      </el-form-item>
      <el-form-item label='签收时间'>
        <el-date-picker
          v-model='searchCondition.sign_time_range'
          type='daterange'
          style='width: 240px'
          align='right'
          unlink-panels
          range-separator='至'
          start-placeholder='开始日期'
          end-placeholder='结束日期'
          value-format='yyyy-MM-dd'
          :picker-options='pickerOptions'>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button icon='el-icon-search' type='primary' @click='handleQuery'>搜索</el-button>
        <el-button icon='el-icon-s-order' type='primary' @click='handleExpress'>快递清单</el-button>
      </el-form-item>
    </el-form>
    <!--    product_specimen_list-->
    <div class='default-table radius_bg'>
      <el-table :data='dataList' highlight-current-row>
        <el-table-column align='center' label='序号' type='index' width='55'></el-table-column>
        <el-table-column align='center' label='产品录入时间' prop='created_at' show-overflow-tooltip
                         width='140'></el-table-column>
        <el-table-column align='center' label='产品图' prop='cover' width='70'>
          <template slot-scope='{row}'>
            <product-image :image-url='row.cover_url' style='width: 40px;height: 40px' />
          </template>
        </el-table-column>
        <el-table-column align='center' label='商品名' prop='product_title' width='200'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='规格' prop='spec_name' show-overflow-tooltip
                         width='80'></el-table-column>
        <el-table-column align='center' label='负责人' prop='leader_name' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='商家' prop='customer_name' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='寄样提交日期' prop='commit_at' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='寄样数量' prop='qty' width='100' show-overflow-tooltip></el-table-column>
        <el-table-column :formatter='$utils.formatterYorN' align='center' label='是否需要退样' width='100'
                         prop='need_back'
                         show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='快递' prop='com_label' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='快递单号' prop='nu' width='120' show-overflow-tooltip></el-table-column>
        <!--        <el-table-column align='center' label='寄样物流状态' prop='_state' show-overflow-tooltip></el-table-column>-->
        <el-table-column align='center' label='物流信息' width='120' show-overflow-tooltip>
          <template slot-scope='{row}'>
            <el-popover
              v-if='row.logistic_data?.length!=0'
              placement='right'
              popper-class='popperClass'
              trigger='click'
              width='400'>
              <div class='popoverTitle'> 物流详情
              </div>
              <logistic-time-line :logisticData='row.logistic_data' v-if='row.logistic_data'></logistic-time-line>
              <el-button slot='reference' size='small'
                         type='text'> {{ row._state }}
              </el-button>
            </el-popover>
            <span v-else>{{ row._state }}</span>
          </template>
        </el-table-column>
        <el-table-column align='center' label='签收日期' prop='sign_time' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='最后更新时间' prop='updated_at' show-overflow-tooltip></el-table-column>
        <el-table-column align='center' label='备注信息' prop='remark' min-width='160'
                         show-overflow-tooltip></el-table-column>
        <!--        <el-table-column align='center' label='库位编号' prop='_whl_code' show-overflow-tooltip />-->
        <!--        <el-table-column align='center' label='操作人' prop='_created_by' show-overflow-tooltip />-->
        <!--        <el-table-column align='center' label='操作时间' prop='_created_at' show-overflow-tooltip />-->

      </el-table>
      <!--        分页-->
      <pagination :background='true' :hidden='total === 0' :limit.sync='pageData.page_size'
                  :page.sync='pageData.current_page' :total.sync='total' @pagination='getList' />
    </div>

    <div>
      <el-dialog :title='express.info.title' :visible.sync='express.open' center width='80%' append-to-body
                 :close-on-click-modal='false'>
        <div class='table-search'>
          <el-form :model='express.searchCondition' inline size='mini'>
            <el-form-item label='快递单号'>
              <el-input v-model='express.searchCondition.express_number' placeholder='快递单号' clearable/>
            </el-form-item>
            <el-form-item label='库位'>
              <el-input v-model='express.searchCondition.whl_code' placeholder='库位' clearable/>
            </el-form-item>
            <el-form-item label='负责人'>
              <el-input v-model='express.searchCondition.leader_name' placeholder='负责人' clearable />
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='express.loading' :loading='express.loading'
                         @click='expressTakeOverList'>搜索
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div style='width: 95%;margin: 20px auto'>
          <el-table :data='express.list' border style='width: 100%'>
            <el-table-column type='index' width='50' label='序号' header-align='center' align='center' />
            <el-table-column prop='express_number' label='快递单号' min-width='150' header-align='center'
                             align='center' />
            <el-table-column prop='express_nm' label='快递名称' min-width='100' header-align='center'
                             align='center' />
            <el-table-column prop='brand' label='品牌' min-width='80' header-align='center' align='center' />
            <el-table-column prop='_wh_nm' label='仓库名称' min-width='80' header-align='center' align='center' />
            <el-table-column label='照片' min-width='240' header-align='center' align='center'>
              <template slot-scope='{row}'>
                <div style="width:100%"
                     v-if="row.cover_url_json?.length>0">
                  <product-image style="display: inline-block;width: 80px;margin-left: 10px"
                                 :fit="'cover'" v-for="(url,key) in row.cover_url_json" :image-url='url' :key="key"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop='whl_code' label='库位' min-width='60' header-align='center' align='center' />
            <el-table-column prop='leader_name' label='负责人' min-width='60' header-align='center' align='center' />
            <el-table-column prop='_created_nm' label='操作人' min-width='60' header-align='center' align='center' />
            <el-table-column prop='updated_at' label='操作时间' min-width='120' header-align='center' align='center' />
          </el-table>
        </div>
        <div>
          <el-row>
            <el-col :span='24'>
              <div>
                <m-pagination :limit.sync='express.pageData.page_size'
                              :page.sync='express.pageData.current_page'
                              :total.sync='express.pageData.total'
                              :hidden='express.pageData.total===0'
                              @pagination='expressTakeOverList'></m-pagination>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>

</template>
<script>
import SpecimenInfo from '@/pages/lb/product/SpecimenInfo.vue'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect'
import ProductImage from './ProductImage'
import logisticTimeLine from '@/components/LogisticTimeLine.vue'
import expressTakeOver from '@/pages/express_take_over/ExpressTakeOverList.vue'
import { EventBus } from '@/eventBus/event-bus'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductSpecimen',
  components: { SpecimenInfo, ProductLeaderSelect, ProductImage, logisticTimeLine, expressTakeOver },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  data() {
    return {
      dataList: [],
      pageData: { page_size: 10, current_page: 1 },
      total: 0,
      searchCondition: {
        nu: null,
        product_title: null,
        customer_name: null,
        leader_id: null,
        com: null,
        com_label: null,
        sign_time_range: null
      },
      stateOptions: [
        { label: '已处理', value: 'Y', type: 'success', icon: 'el-icon-circle-check' },
        { label: '未处理', value: 'N', type: 'info', icon: 'el-icon-circle-close' }
      ],
      showNewProduct: false,
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      express: {
        open: false,
        loading: false,
        info: { title: '快递清单' },
        searchCondition: { express_number: null, whl_code: null, leader_name: null, created_by: null },
        pageData: { current_page: 1, page_size: 10, total: 0 },
        orderBy: { updated_at: 'desc' },
        list: []
      }
    }
  },
  watch: {
    userPermissions: {
      handler: function() {
        this.handleQuery()
      },
      immediate: true
    }
  },
  methods: {
    beforeClose() {
      this.showNewProduct = false
    },
    //处理搜索条件
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })
      if (!(this.userPermissions.indexOf('product_specimen_all') > -1)) {
        condition.leader_id = this.userInfo.id
      }

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    //获取表单数据
    async getList() {
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData)
      let { list, pages } = await this.$api.queryProductSpecimenList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
    },
    addNewProduct() {
      this.showNewProduct = true
    },
    selectedLeader() {
      this.handleQuery()
    },
    searchData(row) {
      EventBus.$emit('searchLogistic', { nu: row.nu, phone: row.phone || null })
    },
    // 取消按钮
    cancel() {
      this.express.open = false
      this.express.searchCondition = { express_number: null, whl_code: null, leader_name: null, created_by: null }
      this.express.list = []
    },
    handleExpress() {
      this.express.open = true
      this.expressTakeOverList()
    },
    // 库位分布接口
    async expressTakeOverList() {
      this.express.list = []
      this.express.loading = true
      let params = this.handleDialogSearchCondition(this.express)
      let { list, pages } = await this.$api.getExpressTakeOverList(params)
      this.express.list = list
      this.express.pageData.current_page = pages.current_page || 1
      this.express.pageData.page_size = pages.page_size || 10
      this.express.pageData.total = pages.total
      this.express.loading = false
    },
    // 弹出框条件处理
    handleDialogSearchCondition(from) {
      let condition = from.searchCondition
      // 分页 +排序
      Object.assign(condition, from.pageData, { orderBy: from.orderBy })
      return condition
    }
  }
}
</script>
<style lang='scss' scoped>
@import "@/pages/lb/style/main.scss";

.popoverTitle {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;

  > span {
    font-size: 16px;
    float: right;
    color: red;
    cursor: pointer;
  }
}
</style>
